import styles from './History.module.css';

import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

import HistoryList from './HistoryList';

function History() {
  const history = useSelector((state: RootState) => state.timer.history);
  if (history.length === 0) return null;
  return (
    <div className={styles.wrapper}>
      <h2>10 days history</h2>
      <HistoryList listData={ history } />
    </div>
  );
}

export default History;
