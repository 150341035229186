export function secondsToTimeString(seconds: number): string {
  // 10 -> '00:00:10'

  // Alternative: 10 -> '0:0:10'
  // const h   = Math.floor(seconds / 3600); // get hours
  // const m = Math.floor((seconds - (h * 3600)) / 60); // get minutes
  // const s = seconds - (h * 3600) - (m * 60); //  get seconds
  // return `${h}:${m}:${s}`;

  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function timeStringToSeconds(time: string): number {
  // '00:00:00' -> 0
  const split = time.split(':');
  const hours = parseInt(split[0]);
  const minutes = parseInt(split[1]);
  const seconds = parseInt(split[2]);
  return hours * 60 * 60 + minutes * 60 + seconds;
}

export function isToday(timestamp: number) {
  const date = new Date(timestamp);
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}
