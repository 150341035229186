import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { checkIfToday, updateTodayTime } from '../../store/timerSlice';
import TimerButton from './TimerButton';
import TimerClockFace from './TimerClockFace';
import styles from './Timer.module.css';

function Timer() {
  const [isTimerOn, setIsTimerOn] = useState(false);
  const seconds = useSelector((state: RootState) => state.timer.timedToday);
  const dispatch = useDispatch();
  
  const buttonClickHandler = () => {
    isTimerOn
      ? setIsTimerOn(false)
      : setIsTimerOn(true);
  };
  
  useEffect(() => {
    dispatch(checkIfToday()); // check if it is a new day. If so, log to history and reset.
    let timerInterval: any = null;
    if (isTimerOn) {
      timerInterval = setInterval(() => {
        dispatch(updateTodayTime(seconds + 1));
      }, 1000);
    } else {
      if (timerInterval) clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [isTimerOn, dispatch, seconds]);

  return (
    <div className={styles.wrapper}>
      
      <h1 className={styles.title}>
        <span>
          M
          <span className={`${styles['text-primary']} ${ isTimerOn ? styles['text-attention'] : '' }`}>
            on
          </span>
          o
        </span>
        <small className={styles['logo-small']}>Timer</small>
      </h1>

      <TimerClockFace seconds={seconds} stopTimer={() => { setIsTimerOn(false) }} />
      <TimerButton
        text={ isTimerOn ? 'Stop' : 'Start' }
        iconName={ isTimerOn ? 'pause' : 'play_arrow' }
        isPressed={isTimerOn}
        onClickHandler={ buttonClickHandler }
      />

    </div>
  );
}

export default Timer;
