import React from 'react';
import ReactDOM from 'react-dom';
import 'material-icons/iconfont/filled.css';
import './styles/reset.css';
import './styles/variables.css';
import './styles/index.css';
import './styles/typography.css';
import './styles/button.css';
import './styles/round-image.css';
import './styles/tutorial.css';
import './styles/donate.css';
import Main from './pages/Main';
import { store } from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
