import styles from './TimerButton.module.css';

interface TimerButtonProps {
  text: string,
  iconName?: string,
  onClickHandler: () => void,
  isPressed: boolean
}

function TimerButton({ text = "Click Me!", onClickHandler = () => {}, isPressed, iconName }: TimerButtonProps) {
  return (
    <div>
      <button
        className={`button ${styles['timer-button']} ${isPressed ? styles.pressed: ''} ${styles['glow-primary']}`}
        type="button"
        aria-label={text + ' timer'}
        onMouseDown={ onClickHandler }
      >
        <i className="material-icons">{ iconName }</i>
      </button>
    </div>
  );
}

export default TimerButton;
