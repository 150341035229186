import { useEffect, useState } from 'react';
import styles from './TimeInput.module.css';

interface TimeInputProps {
  value: string,
  handleChange: React.ChangeEventHandler<HTMLInputElement>,
  handleBlur?: React.ChangeEventHandler<HTMLInputElement>,
  handleFocus?: React.ChangeEventHandler<HTMLInputElement>,
  name?: string,
  small?: boolean,
}

function TimeInput({
  value,
  handleChange,
  handleBlur = () => {},
  handleFocus = () => {},
  name,
  small
}: TimeInputProps) {
  const [shouldBeColored, setShouldBeColored] = useState(false);

  useEffect(() => {
    setShouldBeColored(true);
    setTimeout(() => {
      setShouldBeColored(false)
    }, 150);
    // make color
    // slowly return to the default color

  }, [value]);

  return (
    <input
      className={
        `${styles.input} ${
          small
            ? styles.small
            : ''
          } ${
            shouldBeColored
              ? styles['text-attention']
              : ''
          }`
      }
      type='number'
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      name={name ? name : ''}
      min='0'
      max='59'
    />
  );
}

export default TimeInput;
