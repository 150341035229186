import './Main.css';
import Timer from '../components/Timer';
import History from '../components/History';

function Main() {
  return (
    <>
      <Timer />
      <History />
    </>
  );
}

export default Main;
