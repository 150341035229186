import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTodayTime } from '../../store/timerSlice';
import { secondsToTimeString, timeStringToSeconds } from '../../utils';
import { InputConfig } from '../../data/types';
import TimeInput from '../TimeInput';
import styles from './TimerClockFace.module.css';

interface TimerClockFaceProps {
  seconds: number,
  stopTimer: () => void
}

function TimerClockFace({ seconds = 0, stopTimer }: TimerClockFaceProps) {
  const [hoursValue, setHoursValue] = useState('');
  const [minutesValue, setMinutesValue] = useState('');
  const [secondsValue, setSecondsValue] = useState('');
  const dispatch = useDispatch();

  const handleSaveTime = () => {
    const timeString = `${hoursValue}:${minutesValue}:${secondsValue}`;
    const timeInSeconds = timeStringToSeconds(timeString);
    dispatch(updateTodayTime(timeInSeconds));
  }

  const createInputConfig = (
    stateValue: string,
    setValueFn: React.Dispatch<React.SetStateAction<string>>,
    maxValue: number
  ): InputConfig => ({
    value: stateValue,
    handleChange: (event) => {
      const { value, min } = event.target;
      if (value.length > 2) return;
      const resultValue = Math.max(
        Number(min),
        Math.min(
          Number(maxValue),
          Number(value),
        )
      );
      setValueFn(resultValue.toString());
    },
    handleBlur: (event) => {
      const value = event.target.value;
      if (value.length === 1) {
        setValueFn(`0${value}`);
      }
      handleSaveTime();
    },
    handleFocus: (event) => {
      const value = event.target.value;
      if (value === '00' || value === '0') {
        setValueFn('0');
      }
      stopTimer();
    },
    maxValue
  });

  const inputConfigs: InputConfig[] = [
    createInputConfig(hoursValue, setHoursValue, 23),
    createInputConfig(minutesValue, setMinutesValue, 59),
    createInputConfig(secondsValue, setSecondsValue, 59),
  ];

  const renderForm = () =>
    inputConfigs.map((config: InputConfig, i: number) => (
      <li
        className={styles['clockface-item']}
        key={`timer-input-${i}`}
      >
        <TimeInput
          value={config.value}
          handleChange={config.handleChange}
          handleBlur={config.handleBlur}
          handleFocus={config.handleFocus}
        />
        { 
          i !== 2
            ? <span className={styles.separator}>:</span>
            : null
        }
      </li>
    ));

  useEffect(() => {
    const [h, m, s] = secondsToTimeString(seconds).split(':');
    setHoursValue(h);
    setMinutesValue(m);
    setSecondsValue(s);
  }, [seconds]);

  return (
    <ul className={styles.wrapper}>
      {renderForm()}
    </ul>
  );
}

export default TimerClockFace;
