import { HistoryItem } from '../../data/types';
import HistoryListItem from './HistoryListItem';
import styles from './HistoryList.module.css';

interface HistoryListProps {
  listData: HistoryItem[]
};

function HistoryList({ listData }: HistoryListProps) {
  if (listData === null) return null;
  const items = listData.map((historyItemData: HistoryItem, i: number) =>
    <HistoryListItem key={`history-list-item-${i + historyItemData.dateTimestamp}`} historyItem={historyItemData} />
  );
  return (
    <>
      <ul className={styles.wrapper}>
        { items }
      </ul>
    </>
  );
}

export default HistoryList;
