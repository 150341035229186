import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoryItem } from '../data/types';
import localStorageHelper from '../services/local-storage-helper';
import { isToday } from '../utils';
import initialTimerState from '../data/initialTimerState';

export interface TimerState {
  history: HistoryItem[],
  timedToday: number,
  currentDate: number // Date Timestamp
}

function getInitialState(): TimerState {
  const savedState = localStorageHelper.getState();
  if (savedState === null) {
    localStorageHelper.setState(initialTimerState);
    return initialTimerState;
  }
  return savedState;
}

const initialState: TimerState = getInitialState();
export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    updateTodayTime: (state, action: PayloadAction<number>) => {
      const isNewDay = !isToday(state.currentDate);
      if (isNewDay) {
        const today = new Date().getTime();

        if (state.timedToday === 0) {
          state.currentDate = today;
          localStorageHelper.setState(state);
          return state;   
        }

        // create new HistoryItem
        const historyItem: HistoryItem = {
          time: state.timedToday,
          dateTimestamp: state.currentDate
        };

        // Add the HistoryItem to history and update localStorage history
        state.history.unshift(historyItem);
        if (state.history.length > 10) {
          state.history.length = 10;
        }

        // zero the timer
        state.timedToday = 0;

        // Set today's currentDate
        state.currentDate = today;

      } else {
        state.timedToday = action.payload;
      }
      // Write to localStorage.
      localStorageHelper.setState(state);
    },
    checkIfToday: (state) => {
      const isNewDay = !isToday(state.currentDate);
      if (!isNewDay) return state;
      
      const today = new Date().getTime();
      const time = state.timedToday;

      if (time === 0) {
        state.currentDate = today;
        localStorageHelper.setState(state);
        return state;
      }

      const historyItem: HistoryItem = {
        time,
        dateTimestamp: state.currentDate
      };
      state.history.unshift(historyItem);
      if (state.history.length > 10) {
        state.history.length = 10;
      }
      
      state.currentDate = today;
      state.timedToday = 0;
      localStorageHelper.setState(state);
    },
    updateHistoryItem: (state, action: PayloadAction<HistoryItem>) => {
      const historyItemIndex = state.history.findIndex(item =>
        item.dateTimestamp === action.payload.dateTimestamp);
        
      state.history[historyItemIndex] = action.payload;
      localStorageHelper.setState(state);
    },
  }
});

// Action creators are generated for each case reducer function
export const { updateTodayTime, checkIfToday, updateHistoryItem } = timerSlice.actions;
export default timerSlice.reducer;
