import { TimerState } from '../store/timerSlice';
export const TIMER_STATE_KEY = 'TIMER_STATE';

export class LocalStorageHelper {
  getState(): TimerState | null {
    const state = localStorage.getItem(TIMER_STATE_KEY);
    if (state !== null) {
      return JSON.parse(state);
    }
    return null;
  }

  setState(state: TimerState) {
    localStorage.setItem(TIMER_STATE_KEY, JSON.stringify(state));
  }
}

export default new LocalStorageHelper();
