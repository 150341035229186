import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HistoryItem, InputConfig } from '../../data/types';
import { updateHistoryItem } from '../../store/timerSlice';
import { secondsToTimeString, timeStringToSeconds } from '../../utils';
import TimeInput from '../TimeInput';
import styles from './HistoryListItem.module.css';

interface HistoryListItemProps {
  historyItem: HistoryItem,
};

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return `
    ${monthNames[date.getMonth()]} 
    ${date.getDate()}, 
    ${date.getFullYear()}
  `;
}

function HistoryListItem({ historyItem }: HistoryListItemProps) {
  const split = secondsToTimeString(historyItem.time).split(':');
  const [hoursValue, setHoursValue] = useState(split[0]);
  const [minutesValue, setMinutesValue] = useState(split[1]);
  const [secondsValue, setSecondsValue] = useState(split[2]);
  const dispatch = useDispatch();

  const handleSaveHistoryItem = () => {
    const timeString = `${hoursValue}:${minutesValue}:${secondsValue}`;
    const timeInSeconds = timeStringToSeconds(timeString);
    const newHistoryItem = {
      ...historyItem,
      time: timeInSeconds
    };
    dispatch(updateHistoryItem(newHistoryItem));
  }

  const createInputConfig = (
    stateValue: string,
    setValueFn: React.Dispatch<React.SetStateAction<string>>,
    maxValue: number
  ): InputConfig => ({
    value: stateValue,
    handleChange: (event) => {
      const { value, min } = event.target;
      if (value.length > 2) return;
      const resultValue = Math.max(
        Number(min),
        Math.min(
          Number(maxValue),
          Number(value),
        )
      );
      setValueFn(resultValue.toString());
    },
    handleBlur: (event) => {
      const value = event.target.value;
      if (value.length === 1) {
        setValueFn(`0${value}`);
      }
      handleSaveHistoryItem();
    },
    handleFocus: (event) => {
      const value = event.target.value;
      if (value === '00' || value === '0') {
        setValueFn('0');
      }
    },
    maxValue
  });

  const inputConfigs: InputConfig[] = [
    createInputConfig(hoursValue, setHoursValue, 23),
    createInputConfig(minutesValue, setMinutesValue, 59),
    createInputConfig(secondsValue, setSecondsValue, 59),
  ];

  const renderForm = () =>
    inputConfigs.map((config: InputConfig, i: number) => (
      <div
        className={styles['clockface-item']}
        key={`history-input-${i}`}
      >
        <TimeInput
          value={config.value}
          handleChange={config.handleChange}
          handleBlur={config.handleBlur}
          handleFocus={config.handleFocus}
          small={true}
        />
        { 
          i !== 2
            ? <span className={styles.separator}>:</span>
            : null
        }
      </div>
    ));
  
  return (
    <li className={styles.wrapper}>
      <p className={styles.date}>
        { formatDate(historyItem.dateTimestamp) }
      </p>
      <div className={styles['form-wrapper']}>
        { renderForm() }
      </div>
      
    </li>
  );
}

export default HistoryListItem;
